import type { AppRouteRecordRaw } from 'common/router/type'
import { getDeviceType } from 'common/utils/system'
import { computed, reactive, ref, watch } from 'vue'
import type { Regular } from '@/types/app'
import { setRegular } from '@/api/app'
import { Order } from '@/types/order'
import { getOrderInfo } from '@/api/order'
import { useRouter } from 'vue-router'

export const route: AppRouteRecordRaw = {
  path: `/cardafrica`,
  component: () =>
    getDeviceType() === 'mobile' ? import('./mobile.vue') : import('./pc.vue')
}

export const usePageHook = () => {
  const router = useRouter()

  const form = ref({
    ex_data: {
      name: '',
      email: '',
      phone: '',
      card_number: '',
      month: '',
      year: ''
    }
  } as Regular)

  const saveLoading = ref(false)
  const loading = ref(false)
  const timer = ref(0)
  const save = () => {
    try {
      loading.value = true
      const jsonObj = {
        ...form.value.ex_data
      }
      const data = {
        ex_data: JSON.stringify(jsonObj)
      }
      setRegular(data).then(() => {
        loading.value = false
        getOrderInfos()
      })
    } catch (error) {
      loading.value = false
    }
  }

  /** 打开轮询 */
  const openPoll = () => {
    if (!timer.value) {
      // 确保定时器未启动
      timer.value = window.setInterval(() => {
        getOrderInfos()
      }, 5000)
    }
  }

  const closePoll = () => {
    if (timer.value) {
      clearInterval(timer.value)
      timer.value = 0 // 清空定时器引用
    }
  }

  const orderInfo = ref({
    extra_param: {}
  } as Order)

  const getOrderInfos = () => {
    getOrderInfo().then(res => {
      const _is_expired = res.is_expired // 是否过期
      const _is_pay = res.status === 1 // 支付状态1：已支付，0未支付
      if (!_is_pay) {
        if (!_is_expired) {
          orderInfo.value = res
          if (
            orderInfo.value.payin_response &&
            orderInfo.value.payin_response.url
          ) {
            window.open(orderInfo.value.payin_response.url, '_self')
          }
          openPoll()
        } else {
          // 订单过期
          router.replace({ name: 'expired' })
          closePoll()
        }
      } else {
        router.replace({ name: 'success' })
        closePoll()
      }
    })
  }

  const init = () => {
    getOrderInfos()
  }
  const card_number = ref()
  const handleCardNumber = (value: string) => {
    form.value.ex_data.card_number = value.replace(/\s/g, '')
  }

  const inputValue = ref('')
  // 监听显示值的变化
  watch(inputValue, newValue => {
    // 如果显示值不符合格式要求，则重置为正确的格式
    if (!/^(\d{0,2}\/?\d{0,2})$/.test(newValue)) {
      inputValue.value = formattedDate.value
    }
  })

  // 计算属性：格式化显示值
  const formattedDate = computed(() => {
    const { month, year } = form.value.ex_data
    if (month && year) {
      return `${month}/${year}`
    } else if (month) {
      return `${month}/`
    }
    return ''
  })

  // 输入事件处理函数
  const handleInput = (event: any) => {
    const rawInput = inputValue.value.replace(/\D/g, '') // 移除非数字字符

    const month = rawInput.slice(0, 2) // 获取月份
    const year = rawInput.slice(2, 6) // 获取年份（最多4位）

    // 格式化月份和年份
    if (month && parseInt(month) > 0 && parseInt(month) <= 12) {
      form.value.ex_data.month = month.padStart(2, '0') // 确保月份是两位数
    } else {
      form.value.ex_data.month = '' // 如果月份无效，则清空
    }

    if (year && year.length <= 4) {
      // 检查年份是否超过4位
      form.value.ex_data.year = year // 设置年份
    } else {
      // 截取前4位作为有效年份
      form.value.ex_data.year = year.slice(0, 4)
    }

    // 更新输入框显示值
    if (form.value.ex_data.month && !form.value.ex_data.year) {
      inputValue.value = `${form.value.ex_data.month}/` // 自动补全斜杠
    } else if (form.value.ex_data.month && form.value.ex_data.year) {
      inputValue.value = `${form.value.ex_data.month}/${form.value.ex_data.year}` // 显示完整日期
    } else {
      inputValue.value = '' // 清空输入框
    }
  }

  return {
    card_number,
    orderInfo,
    form,
    saveLoading,
    loading,
    save,
    init,
    inputValue,
    handleInput,
    handleCardNumber
  }
}
