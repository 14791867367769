import type { Router } from 'vue-router'
import { initRouter } from './router'
import useAppStore from '@/store/app'
import { ccLog } from 'common/utils/log'

const whiteList = ['/expired', '/success', 'mpesaError', '/mpesaSuccess']
const getInterceptorRouter = (router: Router) => {
  initRouter(router)
  router.beforeEach((to, from) => {
    if (whiteList.includes(to.path)) {
      return true
    } else {
      const { token } = to.query
      if (token) {
        ccLog(token as string)
        return true
      } else {
        return { name: 'expired', replace: true }
      }
    }
  })

  router.afterEach(to => {
    const { token, language } = to.query
    const appStore = useAppStore()
    appStore.init(token as string, language as string)
  })

  return router
}

export default { getInterceptorRouter }
