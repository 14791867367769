import { get, isNull } from 'lodash-es'
import { onUnmounted, reactive, computed, watch, toRef } from 'vue'
import type { Order } from '@/types/order'
import { getDayDiff, dayToString } from 'common/utils/datetime'
import { useRouter } from 'vue-router'
import useOrderStore from '@/store/order'

type Timer = number | null

export const mpesaTimer = () => {
  const router = useRouter()

  /** 订单信息 */
  const orderInfo = computed(() => useOrderStore().info)
  /** 过期时间戳 */
  const expiredUnix = computed(() => {
    return !orderInfo.value ? null : Math.min(Number(orderInfo.value.expire_time), Number(get(orderInfo.value, 'extra_param.expire_time') || orderInfo.value.expire_time))
  })
  /** 过期日期时间 */
  const expiredDatetime = computed(() => dayToString(expiredUnix.value || '', 'YYYY-MM-DD HH:mm:ss'))
  /** 接口轮询定时器 */
  const loopState = reactive({
    timer: null as Timer,
  })
  const expiredState = reactive({
    timer: null as Timer,
    datetime: null as null | string,
  })
  const expiredCountdown = computed(() => expiredState.datetime)
  /** 已支付，跳转到成功页 */
  watch(() => orderInfo.value.status, (newStatus) => {
    if (newStatus === 1) {
      router.replace({ name: 'mpesaSuccess' })
    }
  }, { immediate: true })
  /** 已过期，跳转到过期页 */
  watch(() => ({ is_expired: orderInfo.value.is_expired, countdown: expiredState.datetime }), ({ is_expired, countdown }) => {
    if (is_expired || countdown === '') {
      router.replace({ name: 'mpesaError' })
    }
  }, { deep: true, immediate: true })
  watch(() => expiredUnix.value, (newUnix) => {
    if (newUnix) {
      startExpiredTimer(newUnix)
    }
  }, { immediate: true })

  /** 开启轮询 */
  const startLoopTimer = (loopFn: () => Promise<Order>, timeout = 5000, extraFn?: () => void) => {
    endLoopTimer()
    loopFn().then((res) => {
      extraFn && extraFn()
    })
    loopState.timer = window.setInterval(() => {
      loopFn().then((res) => {
        extraFn && extraFn()
      })
    }, timeout)
  }
  /** 终止轮询 */
  const endLoopTimer = () => {
    if (!isNull(loopState.timer)) {
      window.clearInterval(loopState.timer)
      loopState.timer = null
    }
  }

  /** 开始过期倒计时 */
  const startExpiredTimer = (newUnix: number) => {
    if (isNull(expiredState.timer)) {
      expiredState.timer = window.setInterval(() => {
        expiredState.datetime = getDayDiff(newUnix)
      }, 1000)
    }
  }
  /** 终止过期倒计时 */
  const endExpiredTimer = () => {
    if (!isNull(expiredState.timer)) {
      window.clearInterval(expiredState.timer)
      expiredState.timer = null
    }
  }
  onUnmounted(() => {
    endExpiredTimer()
    endLoopTimer()
  })

  return {
    orderInfo,
    expiredDatetime,
    expiredCountdown,
    startLoopTimer,
    endLoopTimer,
    startExpiredTimer,
    endExpiredTimer,
  }
}