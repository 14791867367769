import type { AppRouteRecordRaw } from 'common/router/type'
import { getDeviceType } from 'common/utils/system'
import useOrderStore from '@/store/order'
import { mpesaTimer } from '@/hooks/mpesaTimer'
import { computed, reactive, ref } from 'vue'
import type { Regular } from '@/types/app'
import { setRegular } from '@/api/app'
import { modalAlert } from '@/utils/modal'

export const route: AppRouteRecordRaw = {
  path: `/mpesa`,
  component: () =>
    getDeviceType() === 'mobile' ? import('./mobile.vue') : import('./pc.vue')
}

export const usePageHook = () => {
  const orderStore = useOrderStore()

  const form = ref({
    ex_data: {
      phone: ''
    }
  } as Regular)
  const saveLoading = ref(false)
  const timer = ref(0)
  const loading = ref(false)

  const show = ref(false)
  const handleCheck = () => {
    if (form.value.ex_data.phone.includes('254')) {
      show.value = true
    }
  }

  const save = () => {
    try {
      loading.value = true
      const jsonObj = {
        phone: form.value.ex_data.phone
      }
      const data = {
        ex_data: JSON.stringify(jsonObj)
      }
      setRegular(data).then(() => {
        setTimeout(async () => {
          loading.value = false
          startLoopTimer(orderStore.getInfo)
        }, 3000)
      })
    } catch (error) {
      modalAlert({
        type: 'error',
        content: `No information provided`
      })
    }
  }

  const { orderInfo, startLoopTimer, expiredCountdown } = mpesaTimer()

  const init = () => {
    startLoopTimer(orderStore.getInfo)
  }

  return {
    orderInfo,
    form,
    saveLoading,
    handleCheck,
    show,
    loading,
    expiredCountdown,
    save,
    init
  }
}
